$(window).on('load', function() {
    var windowWidth = $(window).width();
    if (windowWidth > 1024) $("#carousel").waterwheelCarousel({
        horizon: 10,
        horizonOffset: -54,
        horizonOffsetMultiplier: .1,
        separation: 280,
        speed: 600,
        opacityMultiplier: 1,
        activeClassName: "current_gallery"
    })
});


wow = new WOW(
    {
        boxClass:     'wow',      // default
        animateClass: 'animated', // default
        offset:       0,          // default
        mobile:       true,       // default
        live:         true        // default
    }
  )
  wow.init();

  
// $(document).ready(function(){
//     $(".collapse.show").each(function(){
//         $(this).prev(".card-header").find(".fa").addClass("fa-angle-up").removeClass("fa-angle-down");
//     });
//     $(".collapse").on('show.bs.collapse', function(){
//         $(this).prev(".card-header").find(".fa").removeClass("fa-angle-down").addClass("fa-angle-up");
//     }).on('hide.bs.collapse', function(){
//         $(this).prev(".card-header").find(".fa").removeClass("fa-angle-up").addClass("fa-angle-down");
//     });
// });


$(document).ready(function(){
    // Add minus icon for collapse element which is open by default
    $(".collapse.show").each(function(){
        $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
    });
    
    // Toggle plus minus icon on show hide of collapse element
    $(".collapse").on('show.bs.collapse', function(){
        $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
    }).on('hide.bs.collapse', function(){
        $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
    });
});
